<template>
    <div>
        <page-header
            :title="$t('pages.personalInformation.PersonalInformation')"
            :show-back="true"
            @onClose="handleClose"
        />
        <loading-indicator
            v-if="loading"
            :title="$t('global.loadingMessage.loading')"
        />
        <form
            @submit.prevent="UpdateCardHolderInformation"
            class="container"
        >
            <div class="mb-2">
                <input
                    aria-label="Name"
                    type="text"
                    readonly
                    name="name[]"
                    class="form-control disabled-control"
                    :placeholder="$t('pages.personalInformation.Name')"
                    v-model="firstName"
                >
            </div>
            <div class="mb-2">
                <input
                    aria-label="Phone Number"
                    min="0"
                    v-model="mobileNumber"
                    readonly
                    type="text"
                    name="phonenumber[]"
                    class="form-control disabled-control"
                    :placeholder="$t('pages.personalInformation.MobileNumber')"
                >
            </div>
            <div class="mb-2">
                <input
                    aria-label="Email"
                    type="email"
                    readonly
                    name="email[]"
                    class="form-control disabled-control"
                    :placeholder="$t('pages.personalInformation.Email')"
                    v-model="email"
                >
            </div>
            <div class="mb-2">
                <textarea
                    aria-label="Apartment Unit"
                    type="text"
                    readonly
                    name="apt_unit[]"
                    class="form-control disabled-control mb-0"
                    :placeholder="$t('pages.personalInformation.Apt')"
                    v-model="cardHolderAddress"
                />
            </div>
            <p class="text-muted text-center px-lg-4">
                {{ $t('pages.personalInformation.UpdateAddressInfo') }}
                <a :href="supportPhoneNumberLink">{{ formattedSupportPhoneNumber }}</a>
                {{ $t('pages.personalInformation.UpdateAddressInfo2') }}
            </p>
        </form>
    </div>
</template>

<script>
    import { cardHolderDetail } from '@/services/api'
    import PageHeader from '@/components/PageHeader'
    import generic from '@/utils/generic'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { contact_info } from '@/utils/contact-info-dictionary'
    import { navigation } from '@/mixins/navigation'
    import { RouteNames } from '@/routes/router'

    export default {
        name: RouteNames.PERSONAL_DETAILS,
        components: {
            PageHeader,
            LoadingIndicator,
        },
        mixins: [navigation],
        data: function () {
            return {
                loading: false,
                error: false,
                errorText: '',
                firstName: '',
                email: '',
                cardHolderAddress: '',
                addressLine2: '',
                state: '',
                country: '',
                city: '',
                postalCode: '',
                mobileNumber: '',
                supportPhoneNumberLink: `tel:${contact_info.phone}`,
                formattedSupportPhoneNumber: contact_info.dashFormatted,
            }
        },
        mounted() {
            this.$logEvent('view_personal_details')
            this.cardHolderDetail()
        },
        methods: {
            cardHolderDetail: async function () {
                this.loading = true
                try {
                    const response = await cardHolderDetail()
                    console.log(`cardHolderDetail: ${JSON.stringify(response.data)}`)
                    if (!response.data?.success) {
                        this.loading = false
                        return
                    }
                    const customerGroup = response.data.payload.CustomerGroup
                    const addressGroup = response.data.payload.AddressGroup
                    this.firstName = customerGroup.FirstName + ' ' + customerGroup.LastName
                    this.mobileNumber = generic.formatPhoneNumber(customerGroup.HomePhoneNumber)
                    this.email = customerGroup.EmailID
                    this.cardHolderAddress = addressGroup.AddressLine1.concat(', ').concat(addressGroup.City).concat(', ').concat(addressGroup.State).concat(', ').concat(addressGroup.PostalCode)
                    this.loading = false
                } catch (error) {
                    ApiErrorHandler(error)
                }
            },
            handleClose: function () {
                if (this.isFromDeeplink()) {
                    this.returnToRoot()
                } else {
                    this.$router.go(-1)
                }
            },
        },
    }
</script>
